import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "d-flex flex-column align-items-center" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.item.thumbnail)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            height: "100",
            width: "100",
            src: _ctx.item.thumbnail,
            alt: "item thumbnail"
          }, null, 8, _hoisted_2))
        : _createCommentVNode("", true),
      _createVNode(_component_router_link, {
        to: `/item/${_ctx.item.id}`
      }, {
        default: _withCtx(() => [
          _createElementVNode("h4", null, _toDisplayString(_ctx.item.title), 1)
        ]),
        _: 1
      }, 8, ["to"])
    ])
  ]))
}