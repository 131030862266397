
import { Options, Vue } from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import 'leaflet/dist/leaflet.css';
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import * as L from 'leaflet';
import 'leaflet.markercluster';
import 'leaflet.featuregroup.subgroup';
import ApiCalls from '@/services/api-calls';
import MapPopup from '@/components/MapPopup.vue';
import Loader from '@/components/Loader.vue';
import store from '@/store';
import { Category, Marker } from '@/models';

@Options({
  components: { MapPopup, Loader },
})
export default class Map extends Vue {
  api = new ApiCalls();

  map: L.Map = {} as L.Map;

  clusterGroup: L.MarkerClusterGroup = {} as L.MarkerClusterGroup;

  isMapLoading = false;

  isSideMenuOpen = true;

  animationDuration = 400;

  markers: Marker[] | null = null;

  selectedItem: {
    id: number,
    title: string,
    thumbnail: string,
  } | null = null;

  categories: {
    templateId: number,
    label: string,
    isActive: boolean,
    // isGeometriesLoaded: boolean,
    itemIds: number[],
    featureGroup: L.FeatureGroup | null,
    style: { color: string },
  }[] | null = null;

  created(): void {
    this.categories = store.getters.getCategories.map((x: Category) => ({
      templateId: x.id,
      label: x.label,
      isActive: true,
      // isGeometriesLoaded: false,
      itemIds: [],
      markers: null,
      featureGroup: null,
      style: {
        color: 'blue',
      },
    }));
  }

  async mounted(): Promise<void> {
    this.isMapLoading = true;

    this.map = L.map('map', { zoomAnimation: false });
    this.clusterGroup = L.markerClusterGroup().addTo(this.map);

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(this.map);

    this.map.attributionControl.setPrefix('');

    this.categories = this.categories!.map((x) => ({
      ...x,
      // featureGroup: L.featureGroup().addTo(this.map),
      featureGroup: L.featureGroup.subGroup(this.clusterGroup),
    }));
    this.categories.forEach((x) => {
      x.featureGroup!.addTo(this.map);
    });

    // this.categories.forEach((x) => x.featureGroup?.addTo(this.map));

    const promises = this.categories.map((x) => this.api.getItemsByResourceTemplate(x.templateId, Infinity));
    const responses = await Promise.all(promises);

    this.categories = this.categories!.map((y, i) => ({
      ...y,
      itemIds: responses[i].items.map((z: any) => z['o:id']),
    }));

    const markers = await this.api.getMarkers();
    console.log('mar', markers);
    markers.forEach((point: any) => {
      const itemId = point['o:item']['o:id'];
      if (this.categories!.some((x) => x.itemIds.includes(itemId))) {
        console.log('add marker');
        const { featureGroup } = this.categories!.find((x) => x.itemIds.includes(itemId))!;
        console.log('fgr', featureGroup);
        const marker: Marker = {
          itemId,
          lat: point['o-module-mapping:lat'],
          lon: point['o-module-mapping:lng'],
        };
        console.log('a', marker);

        const loaderHTML = (this.$refs as any).refLoader.$el.innerHTML;
        const mapMarker = L.circleMarker([marker.lat, marker.lon]).bindPopup(loaderHTML);
        mapMarker.on('click', () => { this.openPopup(mapMarker, marker.itemId); });
        // mapMarker.addTo(featureGroup!);
        mapMarker.addTo(featureGroup!);
      }
    });

    console.log(this.categories);

    // const bounds = L.latLngBounds([]);
    const bounds = this.categories.reduce((t, c) => {
      t.extend(c.featureGroup?.getBounds() || []);
      return t;
    }, L.latLngBounds([]));

    this.map.fitBounds(bounds);
    // this.map.setView([0, 0], 13);
    this.isMapLoading = false;

    // const markerGroup = L.featureGroup().addTo(this.map);
    // const clusters = L.markerClusterGroup().addTo(markerGroup);

    // this.api.getMarkers().then((response) => {
    //   this.markers = response.map((x: any) => ({
    //     lat: x['o-module-mapping:lat'],
    //     lon: x['o-module-mapping:lng'],
    //     itemId: x['o:item']['o:id'],
    //   }));

    //   if (!this.markers) return;

    //   this.markers.forEach((x) => {
    //     const marker = L.circleMarker([x.lat, x.lon]);
    //     marker.on('click', () => { this.openPopup(marker, x.itemId); });
    //     marker.addTo(clusters);
    //   });
    //   this.map!.fitBounds(markerGroup.getBounds());

    //   this.isMapLoading = false;
    // });
  }

  @Watch('isSideMenuOpen')
  onSideMenuChange(): void {
    console.log('re');
    // this.$nextTick(() => {
    //   this.map!.invalidateSize();
    // });
    setTimeout(() => {
      this.map.invalidateSize();
    }, this.animationDuration);
  }

  onToggleCategory(i: number): void {
    this.categories![i].isActive = !this.categories![i].isActive;

    if (this.categories![i].isActive) {
      this.categories![i].featureGroup!.addTo(this.map);
    } else {
      this.categories![i].featureGroup!.removeFrom(this.map);
    }
  }

  // async onToggleCategory(i: number): Promise<void> {
  //   this.categories![i].isActive = !this.categories![i].isActive;

  //   if (!this.categories![i].isActive) {
  //     this.categories![i].featureGroup!.removeFrom(this.clusterGroup as any);
  //     return;
  //   }

  // if (!this.categories![i].isGeometriesLoaded) {
  //   this.isMapLoading = true;

  //   const markersResponse = await this.api.getMarkersByResourceTemplate(this.categories![i].templateId);

  //   const markers = markersResponse.map((x: any) => ({
  //     lat: x['o-module-mapping:lat'],
  //     lon: x['o-module-mapping:lng'],
  //     itemId: x['o:item']['o:id'],
  //   }));

  //   markers.forEach((x: Marker) => {
  //     const marker = L.circleMarker([x.lat, x.lon]);
  //     marker.on('click', () => { this.openPopup(marker, x.itemId); });
  //     marker.addTo(this.categories![i].featureGroup!);
  //   });

  //   this.categories![i].isGeometriesLoaded = true;
  //   this.isMapLoading = false;
  // }

  //   this.categories![i].featureGroup!.addTo(this.clusterGroup);
  //   this.map.fitBounds(this.categories![i].featureGroup!.getBounds());
  // }

  openPopup(marker: L.CircleMarker, id: number): void {
    // const loaderHTML = (this.$refs as any).refLoader.$el.innerHTML;
    // marker.bindPopup(loaderHTML).openPopup();

    this.api.getItem(id).then((response) => {
      this.selectedItem = {
        id,
        title: response['o:title'],
        thumbnail: response.thumbnail_display_urls.square,
      };

      this.$nextTick(() => {
        marker.setPopupContent((this.$refs as any).refPopupContent.$el.innerHTML);
      });
    });
  }
}
